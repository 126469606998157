import { getTrackingId, logIfDebugEnabled, getCanonicalPathHref } from './utils.es6';

/**
 * Checks if the current browser location contains any of the given path parts.
 *
 * The browser location is determined by looking first at the canonical link tag and
 * as a fallback at the window.location attribute.
 *
 * @param nonRunnablePages String containing path parts separated by comma.
 * @returns {boolean}
 */
export function pathnameContainsNonRunnablePages(nonRunnablePages) {
    if (nonRunnablePages) {
        const substrings = nonRunnablePages.split(',');
        const pathname = getCanonicalPathHref() || window.location.pathname;
        for (let i = 0; i !== substrings.length; i++) {
            let substring = substrings[i];
            if (pathname.includes(substring)) {
                logIfDebugEnabled(`Found matching blacklist entry [${substring}] from blacklist [${nonRunnablePages}] in [${pathname}]`);
                return true;
            }
        }
    }
    return false;
}

/* eslint-enable */
export function makeGatewayCallPromise(url, token, body) {

    logIfDebugEnabled(`Calling ${url} asynchronously.`);

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);

        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.setRequestHeader('X-TrackingId', getTrackingId());
        xhr.setRequestHeader('X-ApplicationName', 'Logincheck');

        xhr.responseType = 'json';
        xhr.withCredentials = false;
        xhr.onload = () => {
            if (xhr.status === 200) {
                let responseValue = xhr.response;
                if (typeof responseValue === 'string' || responseValue instanceof String) {
                    try {
                        responseValue = JSON.parse(responseValue);
                    } catch (ex) {
                        throw Error('Error: responseValue is not a valid JSON object.' + ex.toString());
                    }
                }
                resolve(responseValue);
            } else {
                reject({ status: xhr.status, statusText: xhr.response });
            }
        };
        xhr.onerror = () => {
            return reject({ status: xhr.status, statusText: xhr.response });
        };
        xhr.send(body);
    });
}

function handleApiError(statusCode, errorText) {
    if (statusCode >= 400) {
        // IE11 does not provide a console object if the developer console was not opened before
        // and generates an exception if existence is not checked
        if (console && typeof console.error === 'function') { // eslint-disable-line no-console
            console.error(`LOGIN CHECK [Error] :: (${statusCode}) : ${errorText}`); // eslint-disable-line no-console
        }
    } else {
        logIfDebugEnabled(`Error callback received (${statusCode}) : ${errorText}`);
    }
}
