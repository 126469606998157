export const ME_SESSION_STORAGE_KEYS = {
    profilepicture: 'ow_aem_profilepicture',
    wkoUserData: 'ow_aem_wkoUserData',
    openedVehicleRelocation: 'ow_aem_openedVehicleRelocation',
    openedProductConsentWizard: 'ow_aem_openedProductConsentWizard',
    openedProductConsentWizardB2B: 'ow_aem_openedProductConsentWizardB2B',
    userAssociatedDealers: 'ow_aem_userAssociatedDealers',
    missingDataConfig: 'ow_aem_missingDataConfig',
    openedNationalConsent: 'ow_aem_openedNationalConsent',
    consent: 'ow_aem_consent',
    marketLookupTable: 'ow_aem_marketLookupTable',
    customerConsentForProductInformation: 'ow_aem_customerConsentForProductInformation',
    customerConsentForProductInformationB2B: 'ow_aem_customerConsentForProductInformationB2B',
    declarationOfConsent: 'ow_aem_enableDataUsages',
    openedDeclarationOfConsent: 'ow_aem_openedDeclarationOfConsent',
    openedMarketRedirect: 'ow_aem_openedMarketRedirect',
    dataUsageScopes: 'ow_aem_dataUsageScopes',
    b2bActiveProfile: 'mhb2b_active_profile',
    completedProfileWithCar: 'me--completedProfileWithCar',
};